import React from 'react';

import BehavioralGenome from 'components/pages/platform/behavioral-genome';
import Benefits from 'components/pages/platform/benefits';
import Hero from 'components/pages/platform/hero';
import Industries from 'components/pages/platform/industries';
import ProductAdoption from 'components/pages/platform/product-adoption';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const Platform = () => (
  <MainLayout pageMetadata={SeoMetadata.platform}>
    <Hero />
    <BehavioralGenome />
    <Industries />
    <ProductAdoption />
    <Benefits />
  </MainLayout>
);

export default Platform;
