import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import HeroWithImage from 'components/shared/hero-with-image';

const title = 'A New Way To Work with Behavioral Data';
const description =
  'Yobi centralizes the acquisition and maintenance of third party datasets, eliminating the complexity created by managing multiple partners, varying quality and technical capability, and unclear ethical motivations.';

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pages/platform/hero/image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 710)
        }
      }
    }
  `);
  return <HeroWithImage title={title} description={description} textSize="2xl" image={image} />;
};

export default Hero;
