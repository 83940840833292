import React from 'react';

import CardWithIcon from 'components/shared/card-with-icon';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

import Icon1 from './images/icon-1.inline.svg';
import Icon2 from './images/icon-2.inline.svg';
import Icon3 from './images/icon-3.inline.svg';

const items = [
  {
    icon: Icon1,
    title: 'Intelligence',
    description:
      'Access critical data needed to understand customer behavior and competitive performance',
    linkText: 'Learn more',
    linkUrl: '/intelligence',
  },
  {
    icon: Icon2,
    title: 'Growth',
    description: 'Hyper-target your marketing to efficiently acquire new customers',
    linkText: 'Learn more',
    linkUrl: '/growth',
  },
  {
    icon: Icon3,
    title: 'Personalization ',
    description: 'Extract predictive signals and models to scale customer engagement and revenue',
    linkText: 'Learn more',
    linkUrl: '/personalization',
  },
];

const BehavioralGenome = () => (
  <section className="pb-32 pt-32 lg:pb-40 gradient-background-gray">
    <Container className="text-center">
      <Heading className="max-w-[1008px] mx-auto" tag="h2" size="lg">
        Yobi’s Products are Powered by our Behavioral Genome
      </Heading>
      <Link className="mt-8" type="arrow" theme="primary-2" to="/behavior-genome">
        Behavioral Genome
      </Link>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-11">
        {items.map(({ icon: Icon, title, description, linkText, linkUrl }, index) => (
          <CardWithIcon
            title={title}
            description={description}
            linkUrl={linkUrl}
            linkText={linkText}
            icon={Icon}
            theme="white"
            key={index}
          />
        ))}
      </div>
    </Container>
  </section>
);

export default BehavioralGenome;
