import React from 'react';

import Button from 'components/shared/button';
import CardWithNumber from 'components/shared/card-with-number';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const title = 'Benefits of Yobi’s Platform';
const buttonText = 'Get Started';
const items = [
  {
    title: 'Fast',
    description: 'Receive query results in 30 minutes or less',
  },
  {
    title: 'Simple',
    description: 'No engineering or data science skill required to navigate platform',
  },
  {
    title: 'Intelligent ',
    description: 'Powered by machine learning',
  },
  {
    title: 'Actionable',
    description: 'Experience the results',
  },
];

const Benefits = () => (
  <section className="relative pb-40 mt-16 sm:mt-28 pt-28 lg:pt-32 lg:mt-44 -mb-44 gradient-background-gray after:absolute after:-bottom-96 after:gradient-background-gray after:w-full after:h-96">
    <Container className="flex flex-col grid-cols-12 text-center lg:text-left lg:grid gap-x-8">
      <div className="col-start-1 col-end-5">
        <Heading tag="h2" size="lg">
          {title}
        </Heading>
        <Button to="#request-demo" className="mt-8" theme="primary" size="md">
          {buttonText}
        </Button>
      </div>
      <div className="col-start-5 col-end-13 mt-8 lg:mt-0">
        <div className="grid grid-cols-1 gap-8 text-left md:grid-cols-2">
          {items.map(({ title, description }, index) => (
            <CardWithNumber title={title} description={description} index={index} key={index} />
          ))}
        </div>
      </div>
    </Container>
  </section>
);

export default Benefits;
