import React, { useState, useCallback } from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import BgIcon from './images/bg.inline.svg';
import ProductIconActive from './images/product-active.inline.svg';
import ProductIcon from './images/product.inline.svg';
import RevenueIconActive from './images/revenue-active.inline.svg';
import RevenueIcon from './images/revenue.inline.svg';
import SalesIconActive from './images/sales-active.inline.svg';
import SalesIcon from './images/sales.inline.svg';
import ScienceIconActive from './images/science-active.inline.svg';
import ScienceIcon from './images/science.inline.svg';
import UserIcon from './images/user.inline.svg';

const title = `Product Adoption <span>Across the Organization</span>`;
const items = [
  {
    icon: SalesIcon,
    iconActive: SalesIconActive,
    title: 'Marketing / Sales',
    text: 'Where can I reach my future customers?',
    active: true,
  },
  {
    icon: ProductIcon,
    iconActive: ProductIconActive,
    title: 'Product',
    text: 'How do I increase time spent on my site?',
  },
  {
    icon: RevenueIcon,
    iconActive: RevenueIconActive,
    title: 'Revenue',
    text: 'How effective were ads on our app vs competitors?',
  },
  {
    icon: ScienceIcon,
    iconActive: ScienceIconActive,
    title: 'Data Science',
    text: 'What collection of behavioral traits are most predictive of purchase behavior?',
  },
];

const ProductAdoption = () => {
  const [isInitialElementActive, setIsInitialElementActive] = useState(true);

  const handleMouseEnter = useCallback(() => {
    if (!isInitialElementActive) {
      return;
    }
    setIsInitialElementActive(false);
  }, [isInitialElementActive, setIsInitialElementActive]);

  return (
    <section className="mt-40 lg:mt-56">
      <Container>
        <Heading className="mb-16 text-center" tag="h2" size="lg" withHighlightedWords asHTML>
          {title}
        </Heading>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4 ">
          {items.map(({ icon: Icon, iconActive: IconActive, title, text }, index) => {
            const isActive = isInitialElementActive && index === 0;

            return (
              <div className="cursor-pointer group" key={index} onMouseEnter={handleMouseEnter}>
                <div className="relative flex items-center justify-center">
                  <BgIcon />
                  <Icon className="absolute top-4" />
                  <IconActive
                    className={`absolute top-4 opacity-0 ${
                      isActive
                        ? 'md:opacity-100 md:visible'
                        : 'invisible group-hover:opacity-100 group-hover:visible'
                    }`}
                  />
                </div>

                <Heading
                  className={`text-center group-hover:text-primary-1 ${
                    isActive && 'md:text-primary-1'
                  }`}
                  tag="h3"
                  size="sm"
                >
                  {title}
                </Heading>
                <div
                  className={`relative px-5 py-4 mt-8 text-white bg-black rounded-t-2xl rounded-bl-2xl group-hover:opacity-100 ${
                    isActive ? 'opacity-100' : 'md:opacity-0'
                  }`}
                >
                  {text}
                  <UserIcon className="absolute -bottom-6 right-4" />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default ProductAdoption;
