import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import CarIcon from './images/car.inline.svg';
import CartIcon from './images/cart.inline.svg';
import CoinsIcon from './images/coins.inline.svg';
import CpgIcon from './images/cpg.inline.svg';
import EntertainmentIcon from './images/entertainment.inline.svg';
import HouseIcon from './images/house.inline.svg';
import Logo from './images/logo.inline.svg';
import MediaIcon from './images/media.inline.svg';
import RetailIcon from './images/retail.inline.svg';
import ServiceIcon from './images/service.inline.svg';
import shape from './images/shape.svg';

const items = [
  { icon: CartIcon, title: 'E-commerce' },
  { icon: MediaIcon, title: 'Media & Advertising' },
  { icon: CoinsIcon, title: 'Finance' },
  { icon: CarIcon, title: 'Auto' },
  { icon: EntertainmentIcon, title: 'Entertainment' },
  { icon: HouseIcon, title: 'Restaurant & Hospitality' },
  { icon: RetailIcon, title: 'Retail' },
  { icon: CpgIcon, title: 'CPG' },
  { icon: ServiceIcon, title: 'Consumer Service' },
];
const Industries = () => (
  <section className="relative before:absolute before:gradient-background-gray before:w-full before:h-1/2">
    <Container>
      <div className="px-8 pt-16 text-center md:px-16 lg:px-24 gradient-background-blue rounded-2xl pb-44">
        <img
          className="absolute bottom-0 -translate-x-1/2 left-1/2"
          src={shape}
          alt=""
          aria-hidden
        />
        <Heading className="relative max-w-[696px] mx-auto" tag="h2" theme="white" size="md">
          Enabling transformative data science capabilities for key industries
        </Heading>
        <div className="flex flex-wrap justify-center gap-6 mt-12 rounded md:gap-8 md:mt-20">
          {items.map(({ icon: Icon, title }, index) => (
            <div
              className="relative flex space-x-1.5 md:space-x-2.5 items-center p-2 pr-6 gradient-background-gray-primary shadow-inner-sm rounded-4xl"
              key={index}
            >
              <Icon />
              <span className="text-sm font-bold md:text-base">{title}</span>
            </div>
          ))}
        </div>
        <div className="absolute -translate-x-1/2 rounded-full shadow-3xl -bottom-15 left-1/2">
          <Logo className="mix-blend-multiply" />
        </div>
      </div>
    </Container>
  </section>
);

export default Industries;
